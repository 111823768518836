import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { sendEmergencyMessage } from '../services/rent';
import { fetchUserData, fetchUserProfileImage } from '../services/userService';
import axios from 'axios';
import Payment from '../pages/payment'; // Payment 컴포넌트 불러오기

function Test({ isOpen, onClose, gpsId, isRentalMode }) {
  const [showMessage, setShowMessage] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [showBreathMessage, setShowBreathMessage] = useState(false);
  const [showFaceRecognitionMessage, setShowFaceRecognitionMessage] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [returnAlc, setReturnAlc] = useState(false);
  const [returnFace, setReturnFace] = useState(false);
  const [Alc, setAlc] = useState(false);
  const [face, setFace] = useState(false);
  const [faceMessage, setFaceMessage] = useState(false);
  const [memberInfo, setMemberInfo] = useState({});
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [wsMessages, setWsMessages] = useState([]);
  const [ws, setWs] = useState(null);
  const [titleText, setTitleText] = useState('인증을 진행해주세요');
  const [buttonText, setButtonText] = useState("대여하기");
  const [buttonText2, setButtonText2] = useState("반납하기");
  const [AuthState, setAuthState] = useState();
  const [StartReturn, setStartReturn] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentStart, setPaymentStart] = useState(false);
  const [amount, setAmount] = useState();
  const [customerName, setCustomerName] = useState();
  const [AlcFail, setAlcFail] = useState(false);
  const [FaceFail, setFaceFail] = useState(false);
  const [ReturnAlcFail, setReturnAlcFail] = useState(false);
  const [ReturnFaceFail, setReturnFaceFail] = useState(false);


  // 메시지 출력
  const handleWebSocketMessage = (data) => {
    console.log("서버로부터 받은 메시지:", data);
    setWsMessages(prevMessages => [...prevMessages, data]);

    // 메시지 상태
    switch (data) {

      case "33":
        handleAAA();
        sendMessageToAll();
        break;

      case "5":
        setAlc(true);
        break;

      case "2":
        AAA(); // 대여하기O로 변환
        setFace(true);

        break;

      case "20":
        setReturnAlc(true);
        break;

      case "50":
        setReturnFace(true);
        break;

      case "10":
        setStartReturn(true);
        break;

      case "22":
        setFaceFail(true);
        break;

      case "55":
        setAlcFail(true);
        break;

      //반납 음주측정 실패
      case "200":
        setReturnAlcFail(true);
        break;

      //반납 얼굴인증 실패
      case "300":
        setReturnFaceFail(true);
        break;

      case "reset":

        setPaymentStart(true);
        break;

      default:
        break;
    }
  };

  // 결제 로직 실행 함수
  const handlePaymentReset = async () => {
    try {
      const responseRecord = await axios.get('http://3.146.146.201:8080/record/recent');
      console.log('가져온 결제 정보:', responseRecord.data);

      setAmount(responseRecord.data.price);
      setCustomerName(responseRecord.data.username);
      setShowPayment(true); // Payment 컴포넌트 보이도록 설정
    } catch (error) {
      console.error('API 요청 중 오류 발생:', error);
    }
  };

  // 반납하기 메시지 전송 
  const sendNumber10 = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log("웹소켓 연결 상태:", ws.readyState);
      ws.send('10');
    } else {
      console.error('WebSocket이 연결 상태가 아닙니다.');
    }
  };


  // 웹소켓 접속
  const ConnectWebSocket = async () => {
    try {
      const memberInfo = await fetchUserData();
      setMemberInfo(memberInfo);
      const username = encodeURIComponent(memberInfo.username);
      const wsUrl = `ws://192.168.238.197:8765/ws?username=${username}`;
      const newWs = new WebSocket(wsUrl);
      newWs.onopen = () => {
        console.log("WebSocket 연결 성공");
        setWs(newWs); // WebSocket이 연결되었을 때 상태를 업데이트
        sendNumber7(newWs); // WebSocket이 열렸을 때 sendNumber7 호출
      };
      newWs.onmessage = (event) => handleWebSocketMessage(event.data);


      // Fetch profile image URL
      const imageUrl = await fetchUserProfileImage(memberInfo.memberId);
      setProfileImageUrl(imageUrl);


    } catch (error) {
      console.error('API 호출 또는 WebSocket 연결 중 오류 발생:', error);
    }
  };

  const sendMessageToAll = async () => {
    try {
      const response = await sendEmergencyMessage();
      alert(response);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleStartAuth = async () => {
    try {
      let response;
      if (buttonText === "확인") {
        onClose();
        window.location.reload();
        ws.send('7');
      } else {
        setTitleText('대여하기 인증 진행 중');
        sendNumber();
        setShowMessage(true);
        setCountdown(3);
        setIsButtonDisabled(true);

      }
    } catch (error) {
      console.error('API 요청 중 오류 발생:', error);
    }
  };

  const localStoregpsId = async () => {
    localStorage.setItem("gpsId", gpsId);
  }

  const localRemovegpsId = async () => {
    localStorage.removeItem("gpsId");
  }

  const handleReturnAuth = async () => {
    try {
      let response;
      if (buttonText2 === "결제하기") {
        onClose();
      } else {
        setTitleText('반납하기 인증 진행 중');
        sendNumber10();
        setShowMessage(true);
        setCountdown(3);
        setIsButtonDisabled(true);
      }
    } catch (error) {
      console.error('API 요청 중 오류 발생:', error);
    }
  };

  const sendNumber = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send('1');
    } else {
      console.error('WebSocket이 연결 상태가 아닙니다.');
    }
  };
  const sendNumber7 = (wsInstance) => {
    if (wsInstance && wsInstance.readyState === WebSocket.OPEN) {
      wsInstance.send('7');
    } else {
      console.error('WebSocket이 연결 상태가 아닙니다.');
    }
  };

  const AAA = async () => {
    const response = await axios.post('http://3.146.146.201:8080/rent');
    setAuthState(response.data)
  };

  const deleteGpsData = async () => {

    try {

      const response = await axios.delete(`http://3.146.146.201:8080/gps/${gpsId}`);

      if (response.status === 204) {
        console.log(gpsId)
        console.log('GPS 데이터가 성공적으로 삭제되었습니다.');
      } else {
        console.log(gpsId)
        console.error('GPS 데이터 삭제 실패:', response.status);
      }
    } catch (error) {
      console.log(gpsId)
      console.error('GPS 데이터 삭제 오류:', error);
    }
  };

  const handleAAA = async () => {
    try {
      await AAA();
    } catch (error) {
      console.error('AAA 함수 실행 중 오류 발생:', error);
    }
  };

  const handlePaymentComplete = () => {
    setPaymentStart(false); // 결제 완료 후 상태를 false로 재설정
  };

  useEffect(() => {
    let timer;
    if (showMessage && countdown > 0) {
      timer = setTimeout(() => setCountdown(prev => prev - 1), 1000);
    } else if (showMessage && countdown === 0) {
      setShowMessage(false);
      setShowBreathMessage(true);
      setCountdown(5);
    } else if (showBreathMessage && countdown > 0) {
      timer = setTimeout(() => setCountdown(prev => prev - 1), 1000);
    } else if (showBreathMessage && countdown === 0) {
      setShowBreathMessage(false);
      if (!AlcFail) {
        setCountdown(20); // 얼굴 인식을 위한 타이머 설정
        setShowFaceRecognitionMessage(true);
      }
    } else if (showFaceRecognitionMessage && countdown > 0) {
      timer = setTimeout(() => setCountdown(prev => prev - 1), 1000);
    } else if (showFaceRecognitionMessage && countdown === 0) {
      setShowFaceRecognitionMessage(false);
      setIsButtonDisabled(false);
    } else if (faceMessage && countdown > 0) {
      timer = setTimeout(() => setCountdown(prev => prev - 1), 1000);
    } else if (faceMessage && countdown === 0) {
      setAuthState(true); // 인증 성공 상태 변경
      setFaceMessage(false);
      setIsButtonDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [showMessage, showBreathMessage, showFaceRecognitionMessage, countdown, Alc, face, StartReturn, returnAlc, AlcFail, FaceFail, returnFace]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://3.146.146.201:8080/rent');
        setAuthState(response.data);

        await ConnectWebSocket(); // 웹소켓 연결을 먼저 기다림
      } catch (error) {
        console.error('대여 상태 확인 중 오류 발생:', error);
      }
    };
    fetchData();
  }, []);



  useEffect(() => {
    if (Alc) {
      const timer = setTimeout(() => {
        setShowFaceRecognitionMessage(true);
        setCountdown(18);
        setAlc(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [Alc]);



  useEffect(() => {
    if (face) {
      setShowFaceRecognitionMessage(false);
      setFaceMessage(true);
      setFace(false);
      localStoregpsId();
      setButtonText("확인");
      setIsButtonDisabled(false);
      deleteGpsData();
      alert('대여에 성공했습니다.');
    }
  }, [face]);


  useEffect(() => {
    if (returnFace) {
      setShowFaceRecognitionMessage(false);
      setFaceMessage(true);
      setFace(false);
      localRemovegpsId();
      setButtonText2("결제하기");
      setIsButtonDisabled(false);
    }
  }, [returnFace]);



  useEffect(() => {
    if (StartReturn) {
      sendNumber10();
      setShowMessage(true);
      setCountdown(3);
      setIsButtonDisabled(true);
      setStartReturn(false);
    }
  }, [StartReturn]);



  useEffect(() => {
    if (paymentStart) {
      handlePaymentReset();
      setPaymentStart(false);
    }
  }, [paymentStart]);



  const handleClose = () => {
    if (ws) {
      ws.close();
    }
    onClose();
  };



  useEffect(() => {
    if (AlcFail) {
      handleClose();
      alert('음주 측정에 실패했습니다.');
      window.location.reload();
    }
  }, [AlcFail]);



  useEffect(() => {
    if (FaceFail) {
      handleClose();
      alert('얼굴 인식에 실패했습니다.');
      window.location.reload();
    }
  }, [FaceFail]);


  useEffect(() => {
    if (ReturnAlcFail) {
      handleClose();
      alert('반납 진행 중 음주 측정에 실패했습니다. 관리자에게 문의하세요. 010-5664-5041');
      window.location.reload();
    }
  }, [ReturnAlcFail]);



  useEffect(() => {
    if (ReturnFaceFail) {
      handleClose();
      alert('반납 진행 중 얼굴 인식 2회 실패했습니다. 관리자에게 문의하세요. 010-5664-5041');
      window.location.reload();
    }
  }, [ReturnFaceFail]);



  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="auth-modal-title"
      aria-describedby="auth-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div style={{
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        padding: '20px',
        width: '80%',
        height: '70%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative'
      }}>
        <Typography variant="h5" id="auth-modal-title" sx={{ fontSize: 20, fontFamily: 'Pretendard-Bold', marginBottom: '20px', textAlign: 'center' }}>
          {titleText}
        </Typography>
        {showMessage && (
          <>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 14, fontFamily: 'Pretendard-Bold', top: '30%', textAlign: 'center' }}>
              <span style={{ color: 'red' }}>3초</span> 후에 음주센서에 <span style={{ color: 'red' }}>5초</span> 동안 입김을 불어주세요
            </Typography>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 100, fontFamily: 'Pretendard-Bold', top: '35%', textAlign: 'center' }}>
              {countdown}
            </Typography>
          </>
        )}
        {showBreathMessage && (
          <>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 14, fontFamily: 'Pretendard-Bold', top: '30%', textAlign: 'center' }}>
              <span style={{ color: 'red' }}>5초</span> 동안 <span style={{ color: 'red' }}>음주 측정</span>을 진행해주세요
            </Typography>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 100, fontFamily: 'Pretendard-Bold', top: '35%', textAlign: 'center' }}>
              {countdown}
            </Typography>
          </>
        )}
        {Alc && !showFaceRecognitionMessage && (
          <>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 14, fontFamily: 'Pretendard-Bold', top: '300px', textAlign: 'center' }}>
              <span style={{ color: 'green' }}>음주 측정에 성공했습니다. <br></br> 잠시 후에 얼굴 인식을 시작합니다.</span>
            </Typography>
          </>
        )}
        {showFaceRecognitionMessage && (
          <>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 14, fontFamily: 'Pretendard-Bold', top: '20%', textAlign: 'center' }}>
              <span style={{ color: 'red' }}>얼굴 인식을 진행 중입니다.<br></br> 카메라에 얼굴을 인식해주세요</span>
            </Typography>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 100, fontFamily: 'Pretendard-Bold', top: '30%', textAlign: 'center' }}>
              {countdown}
            </Typography>
            <img
              src={profileImageUrl}
              alt="회원 사진"
              style={{
                position: 'absolute',
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                top: '57%'
              }}
            />
          </>
        )}
        {faceMessage && (
          <>
            <Typography variant="body1" sx={{ position: 'absolute', fontSize: 14, fontFamily: 'Pretendard-Bold', top: '200px', textAlign: 'center' }}>
              <span style={{ color: 'green' }}>얼굴 인식에 성공했습니다.</span>
            </Typography>
          </>
        )}

        <div className='testButton'>
          <Button
            variant="contained"
            sx={{ position: 'fixed', backgroundColor: '#2d2c28', fontSize: 12, fontFamily: 'Pretendard-Bold', width: '25%', left: '20%', top: '75%' }}
            onClick={handleStartAuth}
            disabled={isButtonDisabled || !isRentalMode}
          >
            {buttonText}
          </Button>
          <Button
            variant="contained"
            sx={{ position: 'fixed', backgroundColor: '#2d2c28', fontSize: 12, fontFamily: 'Pretendard-Bold', width: '25%', right: '20%', top: '75%' }}
            onClick={buttonText2 === "결제하기" ? handlePaymentReset : handleReturnAuth}
            disabled={isButtonDisabled || isRentalMode}
          >
            {buttonText2}
          </Button>
          {showPayment && <Payment
            amount={amount}
            customerName={customerName}
            onPaymentComplete={handlePaymentComplete}
          />}
        </div>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#2d2c28', fontSize: 12, fontFamily: 'Pretendard-Bold', width: '50%', marginTop: '10px' }}
          onClick={handleAAA}
        >대여상태 토글
        </Button>
      </div>
    </Modal>
  );
}

export default Test;